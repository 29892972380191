<template>
  <div>
    <v-card>
      <v-skeleton-loader
        :loading="loading"
        type="list-item-avatar-two-line"
      >
        <v-list
          v-if="deviceCommands.length"
          two-line
          subheader
        >
          <v-subheader>Commands</v-subheader>
          <v-list-item @click="showCommand">
            <v-list-item-avatar>
              <v-icon
                color="grey lighten-1"
                v-text="'mdi-contactless-payment-circle'"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ deviceCommands.length }}
              </v-list-item-title>
              <v-list-item-subtitle>Attached Commands</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text
          v-else
        >
          No commands available
        </v-card-text>
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
            block
            text
            color="primary"
            @click="showCommand"
          >
            Manage Commands
          </v-btn>
        </v-card-actions> -->
      </v-skeleton-loader>
    </v-card>

    <DeviceDetailCommandManagement
      ref="deviceCommandManagement"
      :device-commands="deviceCommands"
      :device-id="deviceId"
      @device-commands-updated="updateData"
      @device-commands-deleted="deleteData"
    />
  </div>
</template>

<script>
import DeviceDetailCommandManagement from './DeviceDetailCommandManagement.vue';

export default {
  name: 'DeviceDetailCommand',
  components: { DeviceDetailCommandManagement },
  props: {
    deviceDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      deviceCommands: [],
    };
  },

  computed: {
    deviceId() {
      return Number(this.$route.params.id);
    },
  },

  mounted() {
    this.getDeviceCommands();
  },

  methods: {
    async getDeviceCommands() {
      this.loading = true;
      try {
        const response = await this.$api.getDeviceCommands(this.deviceId);
        this.deviceCommands = response.data.data;
      } catch (error) {
        console.error(error);
        // handleDetailError(error, this);
      } finally {
        this.loading = false;
      }
    },

    showCommand() {
      this.$refs.deviceCommandManagement.dialog = true;
    },

    updateData(deviceCommands) {
      this.deviceCommands = deviceCommands;
    },

    deleteData(deviceCommandId) {
      this.deviceCommands.splice(this.deviceCommands.findIndex((d) => d.id === deviceCommandId), 1);
    },
  },
};
</script>
