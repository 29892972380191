<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Pending Commands</v-toolbar-title>
        <v-spacer />
        <v-btn
          color="primary"
          @click="$refs.formCommandScheduler.dialog = true"
        >
          Add
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="scheduledCommands"
        :loading="loading"
        disable-sort
        mobile-breakpoint="0"
        hide-default-footer
      >
        <template v-slot:item.command="{ item }">
          <span>{{ item.command.description || item.command.name }}</span>
        </template>
        <template v-slot:item.date="{ item }">
          <span>{{ item.available_at | formatDateTime }}</span>
        </template>
        <template v-slot:item.user="{ item }">
          <span>{{ item.user.name }}</span>
        </template>
        <!-- actions -->
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                color="error"
                class="mr-2"
                v-on="on"
                @click="deleteItem(item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <DeviceDetailSendCommandForm
      ref="formCommandScheduler"
      :device-id="deviceDetail.id"
      @success="getItems"
    />
  </div>
</template>

<script>
import DeviceDetailSendCommandForm from './DeviceDetailSendCommandForm.vue';

export default {
  name: 'DeviceDetailSendCommand',
  components: {
    DeviceDetailSendCommandForm,
  },
  props: {
    deviceDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      scheduledCommands: [],
      headers: [
        {
          text: 'Name',
          value: 'command',
        },
        {
          text: 'Scheduled on',
          value: 'date',
        },
        {
          text: 'Created by',
          value: 'user',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
        },
      ],
    };
  },

  mounted() {
    this.getItems();
  },

  methods: {
    async getItems() {
      try {
        this.loading = true;
        const response = await this.$api.getScheduledCommands(this.deviceDetail.id);
        this.scheduledCommands = response.data.data;
      } catch (error) {
        console.error(`Failed fetch scheduled commands. ${error}`);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(item) {
      if (await this.$root.$confirm('Delete', 'Are you sure want to delete this item ?', { color: 'error' })) {
        try {
          await this.$api.deleteScheduledCommand(item.id);
          this.getItems();
          this.$toast.success('Success Delete Data');
        } catch (error) {
          this.$toast.error('Failed Delete Data');
          console.log(error);
        }
      }
    },
  },
};
</script>
