<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Uplinks</v-toolbar-title>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="!selectedDate"
              icon
              @click="onRefresh"
              v-on="on"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh Data</span>
        </v-tooltip>
        <v-spacer />
        <v-btn
          text
          @click="$refs.formExport.dialog = true"
        >
          <v-icon left>
            mdi-file-export
          </v-icon>
          Export
        </v-btn>
        <!-- <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn> -->
      </v-toolbar>
      <v-card-text>
        <UplinkDatePicker
          :allowed-dates="allowedDates"
          :date="latestUplinkDate"
          @change-selected-date="getData"
        />
      </v-card-text>
      <v-divider />
      <v-tabs
        v-model="tab"
        grow
        icons-and-text
        show-arrows
      >
        <v-tab>
          Payloads
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-tab>
        <v-tab>
          Chart
          <v-icon>mdi-chart-line</v-icon>
        </v-tab>
        <v-tab>
          Status
          <v-icon>mdi-information-outline</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        touchless
      >
        <v-tab-item>
          <v-data-table
            :headers="headers"
            :items="payloadsProcessed"
            :loading="loadingPayload"
            :options.sync="options"
            disable-sort
            mobile-breakpoint="0"
            :footer-props="footerProps"
            @pagination="paginateEvent"
          >
            <template
              v-if="loadingAppend"
              v-slot:footer
            >
              <div class="d-flex justify-center align-center my-3">
                <v-progress-circular
                  width="2"
                  size="20"
                  indeterminate
                  color="primary"
                />
                <span class="ml-3">Loading more data...</span>
              </div>
            </template>
            <template v-slot:item.errors="{ item }">
              <table dense>
                <tbody>
                  <tr
                    v-for="data in parsePayloadObject(item.errors)"
                    :key="data.label"
                    style="border"
                  >
                    <td>{{ data.label }}</td>
                    <td style="padding:0 5px">
                      :
                    </td>
                    <td>{{ data.value }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <DeviceDetailPayloadChart :payloads="payloads" />
          <v-card-actions
            v-if="payloadMeta.current_page !== payloadMeta.last_page"
            class="d-flex justify-center"
          >
            <v-btn
              block
              :loading="loadingAppend"
              text
              color="primary"
              @click="loadOlderData()"
            >
              Load older data
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <DeviceDetailPayloadUplinkStatus :status-uplinks="statusUplinks" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <DeviceExportData
      ref="formExport"
      :devices="deviceDetail"
      :date="selectedDate"
    />
  </div>
</template>

<script>
import { formatISO, startOfDay, endOfDay } from 'date-fns';
import { underscoreToSpace, reorderFieldPayload } from '../../utils/utils';
import DeviceDetailPayloadChart from './DeviceDetailPayloadChart.vue';
import DeviceDetailPayloadUplinkStatus from './DeviceDetailPayloadUplinkStatus.vue';
import UplinkDatePicker from './UplinkDatePicker.vue';
import DeviceExportData from './DeviceExportData.vue';
import Echo from '../../services/websocket';

export default {
  name: 'DeviceDetailPayload',
  components: {
    DeviceDetailPayloadChart,
    UplinkDatePicker,
    DeviceExportData,
    DeviceDetailPayloadUplinkStatus,
  },
  props: {
    deviceDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingUplink: false,
      loadingPayload: false,
      loadingAppend: false,
      loadingStatusUplink: false,
      options: {},
      footerProps: {
        'show-first-last-page': true,
        'items-per-page-options': [10, 20, 50, 100, -1],
      },
      dateMenu: false,
      uplinks: [],
      payloads: [],
      payloadMeta: {},
      tab: null,
      selectedDate: '',
      dateMode: 0,
      statusUplinks: [],
    };
  },

  computed: {
    payloadsProcessed() {
      return this.payloads.map((p) => ({
        ...p,
        datetime: this.$options.filters.formatDateTime(p.datetime),
      }));
    },

    headers() {
      return this.payloadKeys.map((pk) => ({
        text: underscoreToSpace(pk),
        value: pk,
        class: pk === 'datetime' ? 'min-width-10' : '',
      }));
    },

    payloadKeys() {
      if (this.payloads.length) {
        let arr = Object.keys(this.payloads[0]);

        // front reorder ~ unshift
        arr = reorderFieldPayload(arr, 'valve', 'front');
        arr = reorderFieldPayload(arr, 'datetime', 'front');

        // back reorder ~ push
        arr = reorderFieldPayload(arr, 'counter', 'back');
        arr = reorderFieldPayload(arr, 'rssi', 'back');
        arr = reorderFieldPayload(arr, 'frequency', 'back');
        arr = reorderFieldPayload(arr, 'batt', 'back');
        arr = reorderFieldPayload(arr, 'charge', 'back');

        return arr;
      }
      return [];
    },

    latestUplinkDate() {
      return this.uplinks && this.uplinks[0] ? this.uplinks[0].date : null;
    },

    isLatestUplinkDate() {
      return this.latestUplinkDate === this.selectedDate;
    },

    allowedDates() {
      return this.uplinks.map((uplink) => uplink.date);
    },
  },

  watch: {
    selectedDate(val) {
      if (val) {
        // if change date, then get new payloads and reset page to 1
        this.onRefresh();
      }
    },
  },

  async created() {
    await this.getUplinks();
    if (this.uplinks.length) {
      this.selectedDate = this.uplinks[0].date;
      this.subscribePayload();
    }
    this.getStatusUplinks();
  },

  beforeDestroy() {
    // leave channel when leave page
    Echo.leaveChannel(`device.${this.deviceDetail.device_id}`);
  },

  methods: {
    async getUplinks(page = 1) {
      this.loadingUplink = true;
      try {
        const result = await this.$api.getUplinks({
          id: this.deviceDetail.id,
          page,
          per_page: 100,
        });
        this.uplinks = result.data.data;
      } catch (error) {
        this.$toast.error('Failed Get Uplinks');
      }
      this.loadingUplink = false;
    },

    async getPayloads(page = 1, isAppendPayload = false) {
      if (isAppendPayload) {
        this.loadingAppend = true;
      } else {
        this.loadingPayload = true;
      }
      try {
        const result = await this.$api.getPayloads({
          id: this.deviceDetail.id,
          date: this.selectedDate,
          page,
        });
        if (!isAppendPayload) {
          this.payloads = result.data.data;
        } else {
          this.payloads = this.payloads.concat(result.data.data);
        }
        this.payloadMeta = result.data.meta;
      } catch (error) {
        this.payloads = [];
        this.$toast.error('Failed Get Payloads');
      }
      this.loadingPayload = false;
      this.loadingAppend = false;
    },

    async getPayloadsRange(page = 1, isAppendPayload = false) {
      if (isAppendPayload) {
        this.loadingAppend = true;
      } else {
        this.loadingPayload = true;
      }
      try {
        const result = await this.$api.getUplinks({
          id: this.deviceDetail.id,
          start_date: this.selectedDate[0],
          end_date: this.selectedDate[1],
          page,
          load_payloads: 1,
          per_page: 5,
        });
        const parsedPayloadRange = result.data.data
          // reverse payload order
          .map((val) => val.payloads.reverse())
          // convert nested array to one-level array
          .reduce((a, b) => a.concat(b), []);

        if (!isAppendPayload) {
          this.payloads = parsedPayloadRange;
        } else {
          this.payloads = this.payloads.concat(parsedPayloadRange);
        }
        this.payloadMeta = result.data.meta;
      } catch (error) {
        this.$toast.error('Failed Get Payloads');
      }
      this.loadingPayload = false;
      this.loadingAppend = false;
    },

    paginateEvent(paginationObj) {
      // when reach last page, get more data
      // when no more data, stop get payload
      if (
        paginationObj.page === paginationObj.pageCount
        && this.payloadMeta.current_page !== this.payloadMeta.last_page
      ) {
        this.loadOlderData();
      }
    },

    loadOlderData() {
      if (this.dateMode === 0) {
        this.getPayloads(this.payloadMeta.current_page + 1, true);
      } else if (this.dateMode === 1) {
        this.getPayloadsRange(this.payloadMeta.current_page + 1, true);
      }
    },

    async refreshPayloads() {
      if (this.dateMode === 0) {
        await this.getPayloads();
      } else if (this.dateMode === 1 && this.selectedDate.length === 2) {
        await this.getPayloadsRange();
      }

      this.options.page = 1;
      // if selected date == latest uplink date
      // emit event to parent
      if (this.isLatestUplinkDate) {
        this.updateLastUplink();
      }
    },

    updateLastUplink() {
      this.$emit('refresh', {
        lastUplinkTime: this.payloads[0].datetime,
      });
    },

    subscribePayload() {
      Echo.subscribeChannel(`device.${this.deviceDetail.device_id}`)
        // listen new payloads
        .listen('UplinkReceived', (data) => {
          // push data automatically on latest date, in single date mode
          if (this.selectedDate === data.date && this.dateMode === 0) {
            this.payloads.unshift(data.payload);
            this.updateLastUplink();
          }
        });
    },

    getData(data) {
      // fill helper data
      this.selectedDate = data.selectedDate;
      this.dateMode = data.dateMode;
    },

    async getStatusUplinks(page = 1) {
      this.loadingStatusUplink = true;
      let date = {};
      if (this.dateMode === 0) {
        date = {
          start_date: formatISO(startOfDay(new Date(this.selectedDate))),
          end_date: formatISO(endOfDay(new Date(this.selectedDate))),
        };
      }
      if (this.dateMode === 1) {
        const [startDate, endDate] = this.selectedDate;
        date = {
          start_date: formatISO(startOfDay(new Date(startDate))),
          end_date: formatISO(endOfDay(new Date(endDate))),
        };
      }

      try {
        const result = await this.$api.getStatusUplinks({
          id: this.deviceDetail.id,
          page,
          per_page: 100,
          ...date,
        });
        this.statusUplinks = result.data.data;
      } catch (error) {
        this.$toast.error('Failed Get Status Uplinks');
      }
      this.loadingStatusUplink = false;
    },

    onRefresh() {
      this.refreshPayloads();
      this.getStatusUplinks();
    },

    parsePayloadObject(payloadObj) {
      if (Array.isArray(payloadObj) || !payloadObj) {
        return [];
      }
      return Object.entries(payloadObj).map(([label, value]) => ({ label, value }));
    },
  },
};
</script>
