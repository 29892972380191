<template>
  <v-card class="elevation-0">
    <v-card-text>
      <v-select
        v-model="selectedPayloadKey"
        :items="payloadKeys"
        item-text="text"
        item-value="value"
        label="Payload Key"
        flat
      />
      <v-divider />
      <LineChart
        ref="payloadChart"
        :chart-data="chartDataLine"
        :options="chartOptionsLine"
        :styles="chartContainerStyle"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { underscoreToSpace } from '../../utils/utils';
import LineChart from '../../components/LineChart.vue';

export default {
  name: 'DeviceDetailPayloadChart',
  components: {
    LineChart,
  },
  props: {
    payloads: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartContainerStyle: {
        height: '50vh',
        position: 'relative',
      },
      selectedPayloadKey: null,
    };
  },

  computed: {
    payloadKeys() {
      // get keys from payloads
      // then filter to remove 'datetime' key
      // then map to value-text for select component
      if (this.payloads.length) {
        return Object.keys(this.payloads[0])
          .filter((val) => val !== 'datetime')
          .map((item) => ({
            value: item,
            text: underscoreToSpace(item),
          }));
      }
      return [];
    },

    chartDataLine() {
      // dataset for line chart / timeseries
      return {
        datasets: [
          {
            label: this.selectedPayloadKey,
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: '#5cb85c',
            pointBackgroundColor: '#5cb85c',
            borderWidth: 2,
            pointRadius: 0,
            data: this.createDatasetTimeSeries(this.payloads, this.selectedPayloadKey),
            pointHoverRadius: 5,
          },
        ],
      };
    },

    chartOptionsLine() {
      return {
        responsive: true,
        responsiveAnimationDuration: 500,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            display: true,
            gridLines: {
              // display: false,
              drawOnChartArea: false,
            },
            time: {
              unit: 'hour',
              unitStepSize: 1,
              displayFormats: {
                hour: 'HH:mm',
                min: '00:00',
                max: '23:59',
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0,
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.$options.filters.parsePayloadKey(this.selectedPayloadKey).unit ?? '',
            },
          }],
        },
        elements: {
          line: {
            tension: 0,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (tooltipItem) => {
              const label = this.selectedPayloadKey;
              const { value } = tooltipItem;
              const { unit } = this.$options.filters.parsePayloadKey(this.selectedPayloadKey) ?? '';

              return `${label} : ${value} ${unit}`;
            },
          },
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
      };
    },
  },

  mounted() {
    // auto select first payload key
    this.selectedPayloadKey = this.payloadKeys[0] ? this.payloadKeys[0].value : null;
  },

  methods: {
    createDatasetTimeSeries(payloadsArr, selectedKey) {
      // create x,y data for line chart
      return payloadsArr.map((item) => ({
        x: new Date(item.datetime),
        y: item[selectedKey],
      }));
    },
  },
};
</script>
