<template>
  <v-dialog
    v-model="dialog"
    width="800"
  >
    <v-card>
      <v-card-title
        primary-title
      >
        Device Commands
      </v-card-title>

      <!-- <v-card-text>
        <v-row
          align="stretch"
          no-gutters
        >
          <v-col
            class="grow"
            md="auto"
            cols="12"
          >
            <v-form
              ref="form"
              v-model="formValidity"
            >
              <v-autocomplete
                v-model="selectedCommand"
                :items="commands"
                :loading="loadingCommands"
                label="Available Commands"
                :rules="commandRules"
                item-text="name"
                item-value="id"
                outlined
                dense
              />
            </v-form>
          </v-col>
          <v-col
            class="shrink ml-md-3"
            md="auto"
            cols="12"
          >
            <v-btn
              color="primary"
              block
              @click="attachDeviceCommand"
            >
              Attach
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text> -->

      <v-data-table
        :headers="headers"
        :items="deviceCommands"
        :loading="loadingDeviceCommands"
        disable-sort
        mobile-breakpoint="0"
        hide-default-footer
      >
        <!-- actions -->
        <!-- <template v-slot:item.actions="{ item }">
          <v-btn
            text
            color="error"
            class="mr-2"
            @click="detachDeviceCommand(item)"
          >
            <v-icon left>
              mdi-close-circle
            </v-icon>
            Detach
          </v-btn>
        </template> -->
      </v-data-table>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeviceDetailCommandManagement',
  props: {
    deviceCommands: {
      type: Array,
      required: true,
    },
    deviceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '200px',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Downlink Field',
          value: 'downlink_field',
        },
        {
          text: 'Payload Raw',
          value: 'payload_raw',
        },
        {
          text: 'Status',
          value: 'status',
        },
        // {
        //   text: 'Actions',
        //   value: 'actions',
        //   sortable: false,
        //   align: 'center',
        // },
      ],
      commandRules: [
        (v) => !!v || 'Command is required',
      ],
      loadingCommands: false,
      loadingDeviceCommands: false,
      selectedCommand: null,
      commands: [],
      formValidity: true,
    };
  },

  watch: {
    dialog(state) {
      if (state) {
        this.getCommands();
        this.$nextTick(() => {
          this.resetForm();
        });
      }
    },
  },

  methods: {
    async getCommands() {
      this.loadingCommands = true;
      try {
        const response = await this.$api.getCommands({});
        this.commands = response.data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingCommands = false;
      }
    },

    async attachDeviceCommand() {
      this.$refs.form.validate();
      if (!this.formValidity) return;

      this.loadingDeviceCommands = true;
      try {
        const response = await this.$api.attachDeviceCommand(this.deviceId, {
          command_id: [this.selectedCommand],
        });
        this.resetForm();
        this.$emit('device-commands-updated', response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDeviceCommands = false;
      }
    },

    async detachDeviceCommand(deviceCommand) {
      this.loadingDeviceCommands = true;
      try {
        await this.$api.detachDeviceCommand(this.deviceId, deviceCommand.id);

        this.$emit('device-commands-deleted', deviceCommand.id);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDeviceCommands = false;
      }
    },

    resetForm() {
      this.selectedCommand = null;
      // this.$refs.form.resetValidation();
    },
  },
};
</script>
