<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
    >
      <v-card
        :loading="loading"
        class="mb-5"
      >
        <v-skeleton-loader
          v-if="loading"
          type="heading"
          class="py-5 px-3"
        />
        <v-card-title
          v-else
          class="mb-0 pb-0 d-flex justify-space-between align-center"
        >
          <span class="font-weight-bold">{{ deviceDetail.name || 'No data' }}</span>
          <v-btn
            icon
            @click="$refs.formModal.dialog=true"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <DeviceForm
            ref="formModal"
            :item="deviceDetail"
            @reload-data="getDeviceDetail()"
          />
        </v-card-title>

        <v-skeleton-loader
          :loading="loading"
          type="list-item-avatar-two-line@3"
        >
          <v-list two-line>
            <v-list-item
              v-for="(detail, index) in deviceDetailListItem"
              :key="index"
            >
              <v-list-item-avatar>
                <v-icon
                  color="grey lighten-1"
                  v-text="detail.icon"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="detail.value" />
                <v-list-item-subtitle v-text="detail.key" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions class="d-flex justify-center">
            <DeviceInfo
              ref="deviceInfo"
              :device-detail="deviceDetail"
            />
            <v-btn
              block
              text
              color="primary"
              @click="showInfo"
            >
              More Info
            </v-btn>
          </v-card-actions>
        </v-skeleton-loader>
      </v-card>

      <v-card class="mb-5">
        <DeviceDetailStatus
          :last-uplink-time="deviceDetail.last_uplink_at"
          :loading="loading"
        />
      </v-card>

      <div>
        <DeviceDetailCommand :device-detail="deviceDetail" />
      </div>
    </v-col>
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="mb-5">
        <v-tabs
          v-model="tab"
          grow
          show-arrows
        >
          <v-tab to="uplinks">
            Uplinks
          </v-tab>
          <v-tab to="command-scheduler">
            Command Scheduler
          </v-tab>
          <v-tab to="downlink">
            Send Downlink
          </v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items
        v-model="tab"
        touchless
      >
        <v-tab-item
          value="uplinks"
        >
          <DeviceDetailPayload
            v-if="!isEmptyObject(deviceDetail)"
            :device-detail="deviceDetail"
            @refresh="refresh"
          />
        </v-tab-item>
        <v-tab-item
          value="command-scheduler"
        >
          <DeviceDetailSendCommand
            v-if="!isEmptyObject(deviceDetail)"
            :device-detail="deviceDetail"
          />
        </v-tab-item>
        <v-tab-item
          value="downlink"
        >
          <DeviceDetailDownlink :device-id="deviceId" />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { underscoreToSpace, isEmptyObject, handleDetailError } from '../../utils/utils';
import DeviceDetailPayload from './DeviceDetailPayload.vue';
import DeviceDetailStatus from './DeviceDetailStatus.vue';
import DeviceDetailCommand from './DeviceDetailCommand.vue';
import DeviceInfo from './DeviceInfo.vue';
import DeviceForm from './DeviceForm.vue';
import DeviceDetailSendCommand from './DeviceDetailSendCommand.vue';
import DeviceDetailDownlink from './DeviceDetailDownlink.vue';

export default {
  name: 'DeviceDetail',
  components: {
    DeviceDetailPayload, DeviceDetailStatus, DeviceDetailCommand, DeviceInfo, DeviceForm, DeviceDetailSendCommand, DeviceDetailDownlink,
  },

  data() {
    return {
      deviceDetail: {},
      loading: false,
      tab: null,
    };
  },

  computed: {
    deviceId() {
      return Number(this.$route.params.id);
    },

    deviceDetailListItem() {
      return [
        {
          key: 'Device ID',
          value: this.deviceDetail.device_id || '-',
          icon: 'mdi-chip',
        },
        {
          key: 'Type',
          value: underscoreToSpace(this.deviceDetail.type) || '-',
          icon: 'mdi-cog',
        },
        {
          key: 'Timezone',
          value: this.deviceDetail.timezone || '-',
          icon: 'mdi-map-clock',
        },
      ];
    },
  },

  watch: {
    // handle router params changes
    deviceId() {
      // reset deviceDetail when deviceId changed
      this.deviceDetail = {};
      this.getDeviceDetail();
    },
  },

  async created() {
    await this.getDeviceDetail();
  },

  methods: {
    isEmptyObject,
    underscoreToSpace,

    async getDeviceDetail() {
      this.loading = true;
      try {
        const result = await this.$api.getDeviceDetail(this.deviceId);
        this.deviceDetail = result.data.data;
      } catch (error) {
        handleDetailError(error, this);
      }
      this.loading = false;
    },

    refresh(data) {
      this.deviceDetail.last_uplink_at = data.lastUplinkTime;
    },

    showInfo() {
      this.$refs.deviceInfo.dialog = true;
    },
  },
};
</script>
