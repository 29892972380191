export const deviceTypeOptions = [
  {
    text: 'All Sensor',
    value: 'sensor',
  },
  {
    text: 'All Actuator',
    value: 'actuator',
  },
  {
    text: 'Sensor Soil',
    value: 'sensor_soil',
  },
  {
    text: 'Sensor Smartload',
    value: 'sensor_smartload',
  },
  {
    text: 'Valve',
    value: 'actuator_valve',
  },
];

export default {};
