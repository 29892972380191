<template>
  <v-list
    two-line
    :subheader="!!subheader"
  >
    <v-subheader
      v-if="subheader"
    >
      <span>{{ subheader }}</span>
    </v-subheader>
    <div v-if="itemsProcessed.length">
      <template v-for="(d, idx) in itemsProcessed">
        <v-list-item :key="`list-${idx}`">
          <v-list-item-content>
            <v-list-item-title>{{ d.text }}</v-list-item-title>
            <v-list-item-subtitle>{{ d.value }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- show divider except latest list item -->
        <v-divider
          v-if="idx < (itemsProcessed.length - 1) "
          :key="`divider-${idx}`"
        />
      </template>
    </div>
    <div v-else>
      <div class="px-4">
        No data available
      </div>
    </div>
  </v-list>
</template>

<script>
import { underscoreToSpace } from '@/utils/utils';

export default {
  props: {
    items: {
      type: Object,
      default() { return {}; },
    },
    subheader: {
      type: String,
      default: '',
    },
  },
  computed: {
    itemsProcessed() {
      return Object.entries(this.items).map(([key, value]) => ({
        text: underscoreToSpace(key),
        value: this.getValue(key, value),
      }));
    },
  },
  methods: {
    getValue(key, value) {
      if (key === 'organization' || key === 'integration') {
        return value && value.name;
      }
      if (key === 'last_seen' || key === 'datetime') {
        return this.$options.filters.formatDateTime(value);
      }
      return value || '-';
    },
  },
};
</script>
