<template>
  <v-menu
    ref="menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="290px"
    nudge-right="20"
    nudge-bottom="20"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedUplinkDate"
        label="Uplink Date"
        readonly
        hide-details
        dense
        outlined
        prepend-icon="mdi-calendar"
        v-on="on"
      />
    </template>
    <v-card>
      <v-tabs
        v-model="dateMode"
        grow
      >
        <v-tabs-slider color="primary" />
        <v-tab
          v-for="item in dateModeOptions"
          :key="item.value"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-date-picker
        v-model="selectedDate"
        :allowed-dates="getAllowedDates"
        :range="dateMode == 1"
        :max="nowDate"
        no-title
        scrollable
        color="primary"
        @change="changeDate"
      />
    </v-card>
  </v-menu>
</template>

<script>
import { parseISO, format, compareDesc } from 'date-fns';

export default {
  name: 'UplinkDatePicker',
  props: {
    date: {
      type: [String, Array],
      default: null,
    },
    allowedDates: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      dateMenu: false,
      dateModeOptions: [
        {
          text: 'Single Date',
          value: 'single',
        },
        {
          text: 'Date Range',
          value: 'range',
        },
      ],
      dateMode: 0, // 0:single, 1:range
      selectedDate: '',
    };
  },

  computed: {
    formattedUplinkDate() {
      if (this.dateMode === 0) {
        return this.selectedDate ? format(parseISO(this.selectedDate), 'EEEE, dd MMM yyyy') : '';
      } if (this.dateMode === 1) {
        return this.selectedDate.length === 2 ? `${format(parseISO(this.selectedDate[0]), 'dd MMM yyyy')} - ${format(parseISO(this.selectedDate[1]), 'dd MMM yyyy')}` : '';
      }
      return null;
    },

    nowDate() {
      return format(new Date(), 'yyyy-MM-dd');
    },
  },

  watch: {
    dateMode(dm) {
      if (dm === 0 && typeof this.selectedDate !== 'string') {
        // if single date, and date not in string, set this
        this.setDate(this.nowDate);
      } else if (dm === 1 && !Array.isArray(this.selectedDate)) {
        // if range date, and date not in array, set this
        this.setDate([this.nowDate, this.nowDate]);
      }
    },

    date: {
      immediate: true,
      handler(val) {
        // this.setDate(val || this.nowDate);
        this.setDate(val);
      },
    },
  },

  methods: {
    getAllowedDates(d) {
      if (this.allowedDates.length === 0) return true;
      //   if (this.uplinks.length == 0) {
      //     return true;
      //   }
      return this.allowedDates.includes(d);
    },

    setDate(date) {
      if (Array.isArray(date)) {
        this.dateMode = 1;
      } else {
        this.dateMode = 0;
      }
      this.selectedDate = date;

      // if selectedDate start date after end date, flip date
      if (Array.isArray(date) && date.length == 2) {
        if (compareDesc(parseISO(this.selectedDate[0]), parseISO(this.selectedDate[1])) !== 1) {
          [this.selectedDate[0], this.selectedDate[1]] = [this.selectedDate[1], this.selectedDate[0]];
        }
      }

      //  if change date, emit event to parents
      this.$emit('change-selected-date', {
        selectedDate: date,
        dateMode: this.dateMode,
      });
    },

    changeDate(e) {
      this.setDate(e);
    },
  },
};
</script>
