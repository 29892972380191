<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span>Form Command Scheduler</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-select
            v-model="form.commandId"
            :items="deviceCommands"
            date-time
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Command"
            :rules="commandRules"
          />

          <v-select
            v-model="form.executionTime"
            :items="['now','custom']"
            outlined
            dense
            label="Select execution type"
            :rules="executionTypeRules"
          />

          <v-datetime-picker
            v-if="form.executionTime === 'custom'"
            v-model="form.dateTime"
            label="Datetime"
            date-format="dd MMM yyyy"
            :text-field-props="{outlined:true,dense:true,rules:datetimeRules}"
            :time-picker-props="{format:'24hr'}"
            :date-picker-props="{noTitle:true}"
          >
            <template slot="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          @click="sendScheduledCommand"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, formatISO } from 'date-fns';

export default {
  name: 'DeviceDetailSendCommandForm',
  props: {
    deviceId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      formValidity: true,
      loading: false,
      form: {
        commandId: null,
        dateTime: null,
        executionTime: 'now',
      },
      deviceCommands: [],
      commandRules: [
        (v) => !!v || 'Command is required',
      ],
      executionTypeRules: [
        (v) => !!v || 'Execution type is required',
      ],
      datetimeRules: [
        (v) => !!v || 'Datetime is required',
      ],
    };
  },

  computed: {
    minDate() {
      return format(new Date(), 'yyyy-MM-dd');
    },
  },

  watch: {
    dialog(state) {
      if (state) {
        this.getDeviceCommands();
      } else {
        this.resetForm();
      }
    },
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.resetValidation();
      }, 300);
    },

    resetForm() {
      Object.keys(this.form).forEach((key) => { this.form[key] = null; });
    },

    async getDeviceCommands() {
      try {
        const response = await this.$api.getDeviceCommands(this.deviceId);
        this.deviceCommands = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    getCommandPayload() {
      return [
        {
          command_id: this.form.commandId,
          device_id: this.deviceId,
          execution_time: this.form.executionTime === 'custom' ? formatISO(this.form.dateTime) : null,
        },
      ];
    },

    async sendScheduledCommand() {
      this.$refs.form.validate();
      if (!this.formValidity) return;

      this.loading = true;
      try {
        const response = await this.$api.sendScheduledCommand({
          commands: this.getCommandPayload(),
        });
        if (response.data.status === 'accepted') {
          this.$emit('success');
          this.closeDialog();
          this.$toast.success('Command accepted');
        }
      } catch (error) {
        this.$toast.error('Failed send command');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
