<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="500"
    :retain-focus="false"
  >
    <v-card>
      <v-card-title>
        Device Information
      </v-card-title>

      <v-card-text class="mb-0 pa-0">
        <DeviceInfoList
          :items="deviceDetailInfo"
          subheader="Device"
        />
        <DeviceInfoList
          :items="lorawanDeviceInfo"
          subheader="Lorawan"
        />
        <DeviceInfoList
          :items="latestStatusInfo"
          subheader="Latest Status"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeviceInfoList from './DeviceInfoList.vue';

export default {
  name: 'DeviceInfo',
  components: {
    DeviceInfoList,
  },
  props: {
    deviceDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    deviceDetailInfo() {
      const keyToExclude = ['id', 'created_at', 'updated_at', 'organization_id', 'integration_id', 'last_uplink_at', 'app_server_id', 'latest_payload', 'energies_count', 'lorawan_device', 'commands', 'latest_status'];
      return Object.keys(this.deviceDetail)
        .filter((key) => !keyToExclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.deviceDetail[key];
          return obj;
        }, {});
    },

    lorawanDeviceInfo() {
      if (Array.isArray(this.deviceDetail.lorawan_device)) return {};
      return this.deviceDetail?.lorawan_device ?? {};
    },

    latestStatusInfo() {
      return this.deviceDetail?.latest_status ?? {};
    },
  },
};
</script>
