<template>
  <!-- <v-card> -->
  <v-skeleton-loader
    :loading="loading"
    type="list-item-avatar-two-line"
  >
    <v-list
      v-if="lastUplinkTime"
      two-line
      subheader
    >
      <v-subheader>Status</v-subheader>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon
            :class="checkDeviceConnection(lastUplinkTime)? 'success' : 'error'"
            class="white--text"
            v-text="'mdi-satellite-uplink'"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="checkDeviceConnection(lastUplinkTime) ? 'Online' : 'Offline'" />
          <v-list-item-subtitle>{{ lastUplinkTime | formatDateFromNow }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text v-else>
      No last uplink data
    </v-card-text>
  </v-skeleton-loader>
  <!-- </v-card> -->
</template>

<script>
import { checkDeviceConnection } from '../../utils/utils';

export default {
  name: 'DeviceDetailStatus',
  props: {
    lastUplinkTime: {
      type: String,
      default() {
        return null;
      },
    },

    loading: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },

  data() {
    return {
      intervalId: null,
    };
  },

  mounted() {
    // auto update relative time
    // per minute
    this.intervalId = setInterval(() => {
      this.$forceUpdate();
    }, 60000);
  },

  beforeDestroy() {
    // clear interval auto refresh
    clearInterval(this.intervalId);
  },

  methods: {
    checkDeviceConnection,
  },
};
</script>
