/* eslint-disable global-require */
import Echo from 'laravel-echo';
import store from '../store/index';

let params;
if (process.env.VUE_APP_BROADCAST_DRIVER === 'pusher') {
  window.Pusher = require('pusher-js');
  if (process.env.NODE_ENV === 'development') {
    // window.Pusher.logToConsole = true;
  }

  // pusher params
  params = {
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: true,
    cluster: 'ap1',
    // client: Pusher,
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${store.getters.token}`,
      },
    },
  };
  // } else if (process.env.VUE_APP_BROADCAST_DRIVER == 'socket.io') {
  //   // socket.io params
  //   window.io = require('socket.io-client');
  //   params = {
  //     broadcaster: 'socket.io',
  //     host: process.env.VUE_APP_SOCKET_HOST,
  //     authEndpoint: process.env.VUE_APP_BACKEND_URL + '/broadcasting/auth',
  //     auth: {
  //       headers: {
  //         'Authorization': `Bearer ${TokenService.getTokenVuex().access_token}`
  //       }
  //     }
  //     // appId: process.env.VUE_APP_SOCKET_ID,
  //     // key: process.env.VUE_APP_SOCKET_KEY,
  //   };
} else {
  throw new Error('Invalid Broadcast Driver');
}

const instanceEcho = new Echo(params);

// specify websocket function
export default {
  subscribeChannel(channelName) {
    return instanceEcho.channel(channelName);
  },
  subscribeChannelPrivate(channelName) {
    return instanceEcho.private(channelName);
  },
  leaveChannel(channelName) {
    return instanceEcho.leaveChannel(channelName);
  },
  leaveChannelPrivate(channelName) {
    return instanceEcho.leave(channelName);
  },
};
