<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span>Export Uplink Data</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-autocomplete
            v-if="multipleDevices"
            v-model="selectedDevices"
            :items="deviceItems"
            :loading="loadingDevice"
            :search-input.sync="search"
            :rules="deviceRules"
            cache-items
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Device"
            hide-no-data
            prepend-icon="mdi-developer-board"
            return-object
            multiple
            placeholder="Start typing to search devices"
            @input="search = ''"
          />
          <v-text-field
            v-else
            label="Device (Auto selected)"
            :value="devices.device_id"
            readonly
            disabled
            outlined
            flat
            dense
            prepend-icon="mdi-developer-board"
          />

          <UplinkDatePicker
            :date="selectedDate"
            @change-selected-date="populateData"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <!-- <v-btn
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn> -->
        <v-btn
          color="primary"
          :loading="loading"
          @click="download()"
        >
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import XLSX from 'xlsx/dist/xlsx.mini.min';
import { format } from 'date-fns';
import UplinkDatePicker from './UplinkDatePicker.vue';

export default {
  name: 'DeviceExportData',
  components: { UplinkDatePicker },

  props: {
    date: {
      type: [String, Array],
      default() {
        return format(new Date(), 'yyyy-MM-dd');
      },
    },
    devices: {
      type: [Object, Array],
      default: null,
    },
    multipleDevices: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      formValidity: true,
      dialog: false,
      dateMenu: false,
      selectedDate: null,
      selectedDevices: [],
      uplinks: [],
      loading: false,
      deviceItems: [],
      search: null,
      loadingDevice: false,
      deviceRules: [
        (v) => v.length > 0 || 'Device is required',
      ],
      timerId: null,
    };
  },

  watch: {
    dialog(val) {
      if (val) {
        if (this.date) this.selectedDate = this.date;
        if (this.devices) this.selectedDevices = [this.devices];
      } else {
        this.closeDialog();
      }
    },

    search(keyword) {
      if (!keyword) return;


      // debounce
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        // Items have already been requested
        if (this.loadingDevice) return;
        this.loadingDevice = true;
        this.getDevices(keyword);
      }, 500);
    },
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.resetValidation();
        this.selectedDevices = [];
        this.selectedDate = format(new Date(), 'yyyy-MM-dd');
      }, 300);
    },

    populateData(data) {
      // fill helper data
      this.selectedDate = data.selectedDate;
    },

    async getDevices(keyword) {
      try {
        const response = await this.$api.getDevices({
          keyword,
        });
        this.deviceItems = response.data.data
          .map((item) => ({
            ...item,
            name: `${item.name || 'no name'} (${item.device_id})`,
            id: item.id,
          }));
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDevice = false;
      }
    },

    async download() {
      this.$refs.form.validate();
      if (!this.formValidity) return;

      this.loading = true;
      const payloadData = await this.getAllPayloadData();

      this.loading = false;
      // make Workbook of Excel
      const wb = XLSX.utils.book_new();
      payloadData.forEach((item) => {
        // export json to Worksheet of Excel
        const ws = XLSX.utils.json_to_sheet(item.payloads);
        // add Worksheet to Workbook
        const sheetName = item.device_id.length >= 30 ? item.device_id.substring(0, 30) : item.device_id;
        XLSX.utils.book_append_sheet(wb, ws, sheetName); // sheetAName is name of Worksheet
      });

      const date = Array.isArray(this.selectedDate) ? `${this.selectedDate[0]} - ${this.selectedDate[1]}` : this.selectedDate;

      XLSX.writeFile(wb, `${this.devices ? this.devices.device_id : 'Devices Payload'} (${date}).xlsx`);
    },

    getAllPayloadData() {
      // get uplinks of each device
      const promises = this.selectedDevices.map(async (item) => ({ device_id: item.device_id, payloads: await this.getUplinks(item) }));

      // run promise
      return Promise.all(promises);
    },

    async getUplinks(device) {
      // get all uplinks date
      let page = 1;
      const arrUplinks = [];
      let maxPage = 1;

      let startDate;
      let endDate;
      if (Array.isArray(this.selectedDate)) {
        startDate = this.selectedDate[0];
        endDate = this.selectedDate[1];
      } else {
        startDate = this.selectedDate;
        endDate = this.selectedDate;
      }

      // loop download paginated uplinks payload
      do {
        try {
          const getDevicesUplinks = await this.$api.getUplinks({
            id: device.id,
            start_date: startDate,
            end_date: endDate,
            page,
            load_payloads: 1,
            per_page: 100,
          });

          arrUplinks.push(getDevicesUplinks.data.data);
          maxPage = getDevicesUplinks.data.meta.last_page;

          // set loading iteration and max value per device
          //   this.loading.value += 1;
          //   this.$set(this.loading.eachMax, device, getDevicesUplinks.data.meta.last_page);
        } catch (error) {
          console.log(error);
        }
        page += 1;
      }
      while (page <= maxPage);

      return arrUplinks
        // convert nested array to one-level array phase-1
        .reduce((a, b) => a.concat(b), [])
        // reverse payload per day
        .map((item) => item.payloads.reverse())
        // convert nested array to one-level array phase-2
        .reduce((a, b) => a.concat(b), [])
        // remove time properties
        .map((item) => {
          const formattedDatetime = item.datetime ? this.$options.filters.formatDateTime(item.datetime) : null;
          return {
            ...item,
            datetime: formattedDatetime,
          };
        });
    },
  },
};
</script>
