<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :disabled="loading"
            :counter="40"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.device_id"
            :disabled="loading"
            :rules="deviceIdRules"
            outlined
            dense
            label="Device ID"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.device_eui"
            :disabled="loading"
            outlined
            :rules="deviceEuiRules"
            :counter="16"
            dense
            label="Device EUI"
            @keypress.enter="saveItem"
          />
          <v-select
            v-model="form.type"
            :items="deviceTypeOptions"
            :disabled="loading"
            outlined
            dense
            label="Type"
          />
          <v-autocomplete
            v-model="form.integration_id"
            :items="integrations"
            :disabled="loading"
            :loading="loadingIntegrations"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Integrations"
          />
          <v-autocomplete
            v-if="(isLoraServer || isChirpstack) && !isEdit"
            v-model="form.device_profile_id"
            :items="deviceProfiles"
            :disabled="loading"
            :loading="loadingDeviceProfiles"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Device Profiles"
          />
          <v-autocomplete
            v-model="form.organization_id"
            :items="organizations"
            :disabled="loading"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Organization"
          />
          <v-select
            v-model="form.timezone"
            :items="timezones"
            :disabled="loading"
            outlined
            dense
            label="Timezone"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.longitude"
            :disabled="loading"
            outlined
            dense
            label="Longitude"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.latitude"
            :disabled="loading"
            outlined
            dense
            label="Latitude"
            @keypress.enter="saveItem"
          />
          <v-text-field
            v-model="form.altitude"
            :disabled="loading"
            outlined
            dense
            label="Altitude"
            @keypress.enter="saveItem"
          />
          <v-textarea
            v-model="form.description"
            :disabled="loading"
            :counter="100"
            outlined
            dense
            label="Description"
            auto-grow
          />
          <v-switch
            v-if="!isEdit"
            v-model="form.sync_device"
            class="mt-0 pt-0"
            label="Device will also be created in your app server"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import FormMixin from '../../mixins/FormMixin';
import { deviceTypeOptions } from '../../utils/shared';

export default {
  name: 'DeviceForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        device_id: null,
        device_eui: '',
        type: null,
        name: '',
        organization_id: null,
        integration_id: null,
        device_profile_id: null,
        description: '',
        timezone: null,
        altitude: null,
        latitude: null,
        longitude: null,
        sync_device: true,
      },
      defaultForm: {
        device_id: null,
        device_eui: '',
        type: null,
        name: '',
        organization_id: null,
        integration_id: null,
        device_profile_id: null,
        description: '',
        timezone: null,
        altitude: null,
        latitude: null,
        longitude: null,
        sync_device: true,
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 40 || 'Name must be less than 30 characters',
      ],
      deviceIdRules: [
        (v) => !!v || 'Device ID is required',
      ],
      deviceEuiRules: [
        (v) => !!v || 'Device EUI is required',
        (v) => v == null || (v != null && v.length === 16) || 'Device EUI must be 16 characters',
      ],
      deviceProfiles: [],
      integrations: [],
      loadingIntegrations: false,
      loadingDeviceProfiles: false,
      timezones: [
        {
          text: 'Asia/Jakarta UTC+7',
          value: 'Asia/Jakarta',
        },
        {
          text: 'Asia/Makassar UTC+8',
          value: 'Asia/Makassar',
        },
        {
          text: 'Asia/Jayapura UTC+9',
          value: 'Asia/Jayapura',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['organizations']),

    isSuperadmin() {
      return this.$store.getters.user.is_superadmin;
    },

    deviceTypeOptions() {
      return deviceTypeOptions.filter((t) => t.value !== 'sensor' && t.value !== 'actuator');
    },

    isLoraServer() {
      const integration = this.integrations.find((intg) => intg.id === this.form.integration_id);
      return integration?.driver === 'lora_server' || false;
    },

    isChirpstack() {
      const integration = this.integrations.find((intg) => intg.id === this.form.integration_id);
      return integration?.driver === 'chirpstack' || false;
    },
  },

  watch: {
    dialog(bool) {
      if (bool) {
        // load data if dialog opened
        // only for superadmin
        if (this.isSuperadmin) {
          this.getIntegrations();
          this.$store.dispatch('getOrganizations');
        }
      }
    },
    'form.integration_id': function (integrationId) {
      if ((this.isLoraServer || this.isChirpstack) && !this.isEdit) {
        this.getDeviceProfiles(integrationId);
      }
    },
  },

  methods: {
    addData() {
      return this.$api.addDevice(this.formData);
    },

    updateData() {
      return this.$api.updateDevice({
        id: this.form.id,
        ...this.formData,
      });
    },

    async getIntegrations() {
      this.loadingIntegrations = true;
      try {
        const result = await this.$api.getIntegrations({
          page: 1,
          per_page: 100,
        });
        this.integrations = result.data.data;
      } catch (error) {
        console.log(error.response);
        this.$toast.error('Failed Get Integrations');
      } finally {
        this.loadingIntegrations = false;
      }
    },

    async getDeviceProfiles(integrationId) {
      this.loadingDeviceProfiles = true;
      try {
        const result = await this.$api.getDeviceProfiles(integrationId);
        this.deviceProfiles = result.data.result;
      } catch (error) {
        console.log(error.response);
        this.$toast.error('Failed Get Device Profiles');
      } finally {
        this.loadingDeviceProfiles = false;
      }
    },
  },
};
</script>
