<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="payloads"
      :options.sync="options"
      disable-sort
      mobile-breakpoint="0"
      :footer-props="footerProps"
    />
  </div>
</template>

<script>
import { underscoreToSpace, reorderFieldPayload } from '../../utils/utils';

export default {
  props: {
    statusUplinks: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      options: {},
      footerProps: {
        'show-first-last-page': true,
        'items-per-page-options': [10, 20, 50, 100, -1],
      },
    };
  },
  computed: {
    headers() {
      return this.payloadKeys.map((pk) => ({
        text: underscoreToSpace(pk),
        value: pk,
        class: pk === 'datetime' ? 'min-width-10' : '',
      }));
    },
    payloadKeys() {
      if (this.payloads.length) {
        let arr = Object.keys(this.payloads[0]);

        // front reorder ~ unshift
        arr = reorderFieldPayload(arr, 'port', 'front');
        arr = reorderFieldPayload(arr, 'datetime', 'front');

        return arr;
      }
      return [];
    },
    payloads() {
      return this.statusUplinks.map((uplink) => ({
        ...uplink.payloads,
        datetime: this.$options.filters.formatDateTime(uplink.payloads.datetime),
        // vbat: uplink.payloads.vbat.toFixed(2),
        port: uplink.port,
      }));
    },
  },
};
</script>
