<template>
  <v-card>
    <v-card-title>
      <span>Raw Downlink Form</span>
      <v-spacer />
    </v-card-title>
    <ErrorAlert />
    <v-card-text class="p-3">
      <v-form
        ref="form"
        v-model="formValidity"
      >
        <v-text-field
          v-model="form.port"
          :rules="portRules"
          :disabled="loading"
          outlined
          dense
          type="number"
          label="Port"
          @keypress.enter="sendDownlink"
        />
        <v-text-field
          v-model="form.payload_base64"
          :rules="payloadBase64Rules"
          :disabled="loading"
          outlined
          dense
          label="Payload Base64"
          @keypress.enter="sendDownlink"
        />
        <v-text-field
          v-model="form.payload_bytes"
          :disabled="loading"
          outlined
          dense
          label="Payload Bytes"
          @keypress.enter="sendDownlink"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-3">
      <v-spacer />
      <v-btn
        text
        :disabled="loading"
        @click="resetForm"
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        :loading="loading"
        @click="sendDownlink"
      >
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { handleError } from '@/utils/utils';
import * as types from '@/store/mutation-types';

export default {
  props: {
    deviceId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      formValidity: true,
      loading: false,
      form: {
        port: null,
        payload_base64: null,
        payload_bytes: null,
      },
      portRules: [
        (v) => !!v || 'Port is required',
      ],
    };
  },
  computed: {
    payloadBase64Rules() {
      // required unless payload_bytes exists
      const requiredRule = (v) => !!v || 'Payload Base64 is required';
      return [
        !this.form.payload_bytes ? requiredRule : true,
      ];
    },
  },
  watch: {
    deviceId() {
      this.resetForm();
    },
  },
  methods: {
    async sendDownlink() {
      this.$refs.form.validate();
      if (!this.formValidity) return;

      this.$store.commit(types.ERROR, null);

      this.loading = true;
      try {
        await this.$api.sendDownlink(this.deviceId, this.form);
        this.$toast.success('Send Downlink Succeded');
        this.resetForm();
      } catch (error) {
        handleError(error);
        this.$toast.error('Send Downlink Failed');
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = null;
      });
      this.$refs.form.resetValidation();
      this.$store.commit(types.ERROR, null);
    },
  },
};
</script>
